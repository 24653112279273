<template>
    <div class="container">
        <div class="ranking flex justify-start">
            <div class="goodsRanking">
                <div class="flex justify-between">
                    <div class="title">
                        <span>品牌销售排行榜</span>
                        <div class="inline">
                            <el-date-picker
                                    v-model="time1"
                                    type="daterange"
                                    @change="getTime1"
                                    align="right"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="tabs">
                        <el-tabs type="border-card" v-model="goodsType" @tab-click="brandHandleClick">
                            <el-tab-pane label="商品排行" name="3"></el-tab-pane>
                            <el-tab-pane label="服务排行" name="1"></el-tab-pane>
                            <el-tab-pane label="套餐排行" name="5"></el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
                <div class="changeGoodsRanking">
                    <div class="classify flex justify-between">
                        <el-tabs type="border-card" v-model="sort1" @tab-click="brandClassifyHandleClick">
                            <el-tab-pane label="按销售额排行" name="sumMoney"></el-tab-pane>
                            <el-tab-pane label="按销售量排行" name="countNum"></el-tab-pane>
                        </el-tabs>
                        <b @click="downloadOne">数据导出</b>
                    </div>
                    <el-table :data="ranking1" v-loading="loading1" max-height="9999">
                        <el-table-column type="index" label="排名"></el-table-column>
                        <el-table-column prop="name" label="名称"></el-table-column>
                        <el-table-column label="规格">
                            <template slot-scope="scope">
                                {{scope.row.specs.sku_values}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="sumMoney" label="销售额 （元） "></el-table-column>
                        <el-table-column prop="countNum" label="销售数量 (单)"></el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="projectRanking">
                <div class="flex justify-between">
                    <div class="title">
                        <span>自营销售排行榜</span>
                        <div class="inline">
                            <el-date-picker
                                    v-model="time2"
                                    type="daterange"
                                    @change="getTime2"
                                    align="right"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="tabs">
                        <el-tabs type="border-card" v-model="projectType" @tab-click="autarkyHandleClick">
                            <el-tab-pane label="商品排行" name="4"></el-tab-pane>
                            <el-tab-pane label="服务排行" name="2"></el-tab-pane>
                            <el-tab-pane label="套餐排行" name="6"></el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
                <div class="changeProjectRanking">
                    <div class="classify flex justify-between">
                        <el-tabs type="border-card" v-model="sort2" @tab-click="autarkyClassifyHandleClick">
                            <el-tab-pane label="按销售额排行" name="sumMoney"></el-tab-pane>
                            <el-tab-pane label="按销售量排行" name="countNum"></el-tab-pane>
                        </el-tabs>
                        <b @click="downloadTwo">数据导出</b>
                    </div>
                    <el-table :data="ranking2" v-loading="loading2" max-height="9999">
                        <el-table-column type="index" label="排名"></el-table-column>
                        <el-table-column prop="name" label="名称"></el-table-column>
                        <el-table-column label="规格">
                            <template slot-scope="scope">
                                <span v-if="scope.row.specs">{{scope.row.specs.sku_values}}</span>
                                <span v-else>暂无规格</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sumMoney" label="销售额 （元） "></el-table-column>
                        <el-table-column prop="countNum" label="销售数量 (单)"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="ranking flex justify-start">
            <div class="goodsRanking">
                <div class="title">
                    <span>技师服务排行</span>
                    <div class="inline">
                        <el-date-picker
                                v-model="time3"
                                type="daterange"
                                @change="getTime3"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                </div>
                <div class="changeGoodsRanking">
                    <div class="classify flex justify-between">
                        <div class="alone">服务次数排行</div>
                        <b @click="downloadThree">数据导出</b>
                    </div>
                    <el-table :data="ranking3" v-loading="loading3" max-height="9999">
                        <el-table-column type="index" label="排名"></el-table-column>
                        <el-table-column prop="technician_name" label="技师姓名"></el-table-column>
                        <el-table-column prop="countNum" label="服务次数 (单)"></el-table-column>
                    </el-table>

                </div>
            </div>
            <div class="projectRanking">
                <div class="title">
                    <span>会员消费排行</span>
                    <div class="inline">
                        <el-date-picker
                                v-model="time4"
                                @change="getTime4"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>
                </div>
                <div class="changeProjectRanking">
                    <div class="classify flex justify-between">
                        <el-tabs type="border-card" v-model="sort4" @tab-click="memberHandleClick">
                            <el-tab-pane label="充值额度排行" name="cumulative_recharge"></el-tab-pane>
                            <el-tab-pane label="消费额度排行" name="total_amount"></el-tab-pane>
                        </el-tabs>
                        <b @click="downloadFour">数据导出</b>
                    </div>
                    <el-table :data="ranking4" v-loading="loading4" max-height="9999">
                        <el-table-column type="index" label="排名"></el-table-column>
                        <el-table-column prop="real_name" label="客户姓名"></el-table-column>
<!--                        <el-table-column prop="name" label="客户级别"></el-table-column>-->
                        <el-table-column prop="cumulative_recharge" label="充值总额 （实际） "></el-table-column>
                        <el-table-column prop="total_amount" label="消费总额 (单)"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading1:false,
                loading2:false,
                loading3:false,
                loading4:false,
                years:'',
                days:7,
                type: 'day',
                time1:[],
                time2:[],
                time3:[],
                time4:[],
                ranking1:[],//商品排行
                ranking2:[],//服务排行
                ranking3:[],//技师排行
                ranking4:[],//会员消费排行
                goodsType:'3',//商品类型,1：品牌服务 2：自营服务 3：品牌商品 4：自营商品 5: 品牌套餐 6自营套餐
                projectType:'4',//服务类型,1：品牌服务 2：自营服务 3：品牌商品 4：自营商品 5: 品牌套餐 6自营套餐
                sort1:'sumMoney',//sumMoney:销售额 [默认] / countNum:销售量
                sort2:'sumMoney',//sumMoney:销售额 [默认] / countNum:销售量
                sort3:'sumMoney',
                sort4:'cumulative_recharge',//total_amount:消费总额 / cumulative_recharge:充值总额
                goodsTimeStart:'',//商品开始时间
                goodsTimeEnd:'',//商品结束时间
                projectTimeStart:'',//服务开始时间
                projectTimeEnd:'',//服务结束时间
                technicianTimeStart:'',//技师开始时间
                technicianTimeEnd:'',//技师结束时间
                memberTimeStart:'',//会员开始时间
                memberTimeEnd:'',//会员结束时间
                memberActiveName:'',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                // token:''
            };
        },
        mounted() {
            this.getGoodsData();
            this.getProjectData();
            this.getTechnicianData();
            this.getMemberData();

        },
        methods: {
            //商品订单数排名
            getGoodsData(){
                this.loading = true
                this.$api.statistics.saleRanking({
                    type:this.goodsType,
                    sort:this.sort1,
                    start_date: this.goodsTimeStart,
                    end_date: this.goodsTimeEnd
                }).then(res => {
                    console.log(res.data)
                    this.ranking1 = res.data
                    this.loading1= false
                })
            },
            //品牌销售时间段
            getTime1(){
                if (this.time1 == null){
                    this.goodsTimeStart = ''
                    this.goodsTimeEnd = ''
                }else {
                    this.goodsTimeStart = this.time1[0]
                    this.goodsTimeEnd = this.time1[1]
                }

                this.getGoodsData()
            },
            //品牌类型切换
            brandHandleClick(){
                console.log(this.goodsType)
                // this.goodsTimeStart = ''
                // this.goodsTimeEnd = ''
                this.getGoodsData()
            },
            brandClassifyHandleClick(){
                console.log(this.sort1)
                // this.goodsTimeStart = ''
                // this.goodsTimeEnd = ''
                this.getGoodsData()
            },
            //服务订单数排名
            getProjectData(){
                this.loading = true
                this.$api.statistics.saleRanking({
                    type:this.projectType,
                    sort:this.sort2,
                    start_date: this.projectTimeStart,
                    end_date: this.projectTimeEnd
                }).then(res => {
                    console.log(res.data)
                    this.ranking2 = res.data
                    this.loading2 = false
                })
            },
            //自营销售时间段
            getTime2(){
                console.log(this.time2)
                if (this.time2 == null){
                    this.projectTimeStart = ''
                    this.projectTimeEnd = ''
                }else {
                    this.projectTimeStart = this.time2[0]
                    this.projectTimeEnd = this.time2[1]
                }
                this.getProjectData()
            },
            //自营类型切换
            autarkyHandleClick(){
                console.log(this.projectType)
                // this.projectTimeStart = ''
                // this.projectTimeEnd = ''
                this.getProjectData()
            },
            autarkyClassifyHandleClick(){
                console.log(this.sort2)
                // this.projectTimeStart = ''
                // this.projectTimeEnd = ''
                this.getProjectData()
            },
            //技师时间段
            getTime3(){
                console.log(this.time3)
                if (this.time3 == null){
                    this.technicianTimeStart = ''
                    this.technicianTimeEnd = ''
                }else {
                    this.technicianTimeStart = this.time3[0]
                    this.technicianTimeEnd = this.time3[1]
                }

                this.getTechnicianData()
            },
            //技师排名
            getTechnicianData(){
                this.loading = true
                this.$api.statistics.technicianRanking({
                    sort:this.sort3,
                    start_date: this.technicianTimeStart,
                    end_date: this.technicianTimeEnd
                }).then(res => {
                    console.log(res.data)
                    this.ranking3 = res.data
                    this.loading3 = false
                })
            },
            //会员销售时间段
            getTime4(){
                console.log(this.time4)
                if (this.time4 == null){
                    this.memberTimeStart = ''
                    this.memberTimeEnd = ''
                }else {
                    this.memberTimeStart = this.time4[0]
                    this.memberTimeEnd = this.time4[1]
                }

                this.getMemberData()
            },
            //会员排名
            getMemberData(){
                this.loading = true
                this.$api.statistics.memberRanking({
                    sort:this.sort4,
                    start_date: this.memberTimeStart,
                    end_date: this.memberTimeEnd
                }).then(res => {
                    console.log(res.data)
                    this.ranking4 = res.data
                    this.loading4 = false
                })
            },
            //自营类型切换
            memberHandleClick(){
                console.log(this.sort4)
                // this.memberTimeStart = ''
                // this.memberTimeEnd = ''
                this.getMemberData()
            },
            //导出数据
            downloadOne(){
                this.$api.statistics.downloadOne({
                    type:this.goodsType,
                    start_date:this.goodsTimeStart,
                    end_date:this.goodsTimeEnd,
                    sort:this.sort1
                }).then(res => {
                    console.log(res)
                    let a = document.createElement('a');
                    //ArrayBuffer 转为 Blob
                    let blob = new Blob([res], {
                        type: "application/vnd.ms-excel"
                    });
                    let objectUrl = URL.createObjectURL(blob);
                    console.log(objectUrl)
                    a.setAttribute("href", objectUrl);
                    a.setAttribute("download", '品牌销售排行榜.xls');
                    a.click();
                })
            },
            downloadTwo(){
                    this.$api.statistics.downloadTwo({
                        type:this.projectType,
                        start_date:this.projectTimeStart,
                        end_date:this.projectTimeEnd,
                        sort:this.sort2
                    }).then(res => {
                        console.log(res)
                        let a = document.createElement('a');
                        //ArrayBuffer 转为 Blob
                        let blob = new Blob([res], {
                            type: "application/vnd.ms-excel"
                        });
                        let objectUrl = URL.createObjectURL(blob);
                        console.log(objectUrl)
                        a.setAttribute("href", objectUrl);
                        a.setAttribute("download", '自营销售排行榜.xls');
                        a.click();
                    })
                // window.location.href = 'https://momenapi.memexia.com/api/merchant/export/sale/ranking?type=' + this.projectType + '&sort=' + this.sort2 + '&start_date=' + this.projectTimeStart + '&end_date=' + this.projectTimeEnd
            },
            downloadThree(){
                this.$api.statistics.downloadThree({
                    start_date:this.technicianTimeStart,
                    end_date:this.technicianTimeEnd,
                    sort:this.sort3
                }).then(res => {
                    let a = document.createElement('a');
                    //ArrayBuffer 转为 Blob
                    let blob = new Blob([res], {
                        type: "application/vnd.ms-excel"
                    });
                    let objectUrl = URL.createObjectURL(blob);
                    console.log(objectUrl)
                    a.setAttribute("href", objectUrl);
                    a.setAttribute("download", '技师服务排行榜.xls');
                    a.click();
                })
                // window.location.href = 'https://momenapi.memexia.com/api/merchant/export/technician/ranking?sort=' + this.sort3 + '&start_date=' + this.technicianTimeStart + '&end_date=' + this.technicianTimeEnd
            },
            downloadFour(){
                this.$api.statistics.downloadFour({
                    start_date:this.memberTimeStart,
                    end_date:this.memberTimeEnd,
                    sort:this.sort4
                }).then(res => {
                    let a = document.createElement('a');
                    //ArrayBuffer 转为 Blob
                    let blob = new Blob([res], {
                        type: "application/vnd.ms-excel"
                    });
                    let objectUrl = URL.createObjectURL(blob);
                    console.log(objectUrl)
                    a.setAttribute("href", objectUrl);
                    a.setAttribute("download", '会员消费排行榜.xls');
                    a.click();
                })
                // window.location.href = 'https://momenapi.memexia.com/api/merchant/export/member/ranking?sort=' + this.sort4 + '&start_date=' + this.memberTimeStart + '&end_date=' + this.memberTimeEnd
            },

        },

    };
</script>

<style scoped lang="scss">
    @import "../../assets/common.scss";

    .container{
        background-color: #F5F5F5;
        .ranking{
            margin-bottom: 20px;
            .title:before{
                background: #FF9000;
            }
            .title .inline{
                display: inline-block;
                margin-left: 20px;
            }
            .tabs{

            }
        }
    }
    .ranking .goodsRanking{
        margin-right: 30px;
    }
    .ranking .goodsRanking,
    .ranking .projectRanking{
        width: 800px;
        min-height: 634px;
        background-color: #fff;
        padding: 20PX;
        /*.changeGoodsRanking{*/
        /*    background-color: #F1F1F4;*/
        /*    .el-loading-parent--relative{*/
        /*        background-color: #F1F1F4;*/
        /*    }*/
        /*}*/
        .el-range-editor.el-input__inner{
            width: 300px;
        }
        .classify b{
            display: inline-block;
            width: 100px;
            font-weight: normal;
            line-height: 36px;
            border-radius: 2px;
            text-align: center;
            cursor: pointer;
            margin-left: 20px;
            font-size: 16px;
            color: #17283C;
            border: 1px solid #808080;
        }
    }
    .title{
        position: relative;
        font-size: 16px;
        color: #3C3F42;
        padding-left: 15px;
        margin-bottom: 25px;
    }
    .title:before{
        position: absolute;
        content: '';
        width: 3px;
        height: 17px;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
    }
    .ranking /deep/ .el-tabs--border-card  .el-tabs__content{
       padding: 0;
    }
    .alone{
        background: #f5f7fa;
        border: 1px solid #DCDFE6;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.12),0 0 6px 0 rgba(0,0,0,.04);
        padding: 0 20px;
        height: 40px;
        box-sizing: border-box;
        line-height: 40px;
        display: inline-block;
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        color: #999;
    }
</style>
